/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mixed, object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import {
    Button, DatePicker, Icon, Loader, Modal, notification, NumberInput, Select, TextInput,
} from 'ui-library';
import moment, { unitOfTime } from 'moment/moment';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { FILE_TYPES } from 'pages/ClientOnboardingFlow/pages/NewsPlan/UploadDocumentsPage/validation/const';
import { compareDates, dateFormatZurich, formatDateWithoutLocalConversion } from 'utils/datetime';
import UploadDocuments from 'components/UploadDocuments/UploadDocuments';
import Error from 'components/AtomicStructure/atoms/Error';

import './UploadAdvisoryDocumentModal.css';
import { NUMBER_AND_LETTERS_REGEX } from 'constants/constants';
import { getBase64FromFile } from 'utils/file';
import { ICON_TYPE_CLOSE_SLIM } from 'ui-library/components/Icon';
import { DocumentPayload, DocumentType, useAdvisoryDocumentBase } from './hooks/useAdvisoryDocumentBase';

type UploadAdvisoryDocumentModalProps = {
    show: boolean;
    onClose: (documentItem: DocumentType | null) => void;
    contactGroupId: number;
    inAdvisoryFlow: boolean;
}

type AdvisoryDocumentForm = {
    date: Date | null;
    number: string;
    investmentAmount: number;
    system: string;
    file: any;
}

export const AdvisorySystem = {
    ImpaQt: 0,
    eAdvise: 1,
    PensionPlanner: 2,
};

const ValidityPeriodsByAdvisorySystem: { [p: number]: { amount: number; units: unitOfTime.DurationConstructor; } } = {
    [AdvisorySystem.ImpaQt]: {
        amount: 3,
        units: 'months',
    },
    [AdvisorySystem.eAdvise]: {
        amount: 3,
        units: 'years',
    },
    [AdvisorySystem.PensionPlanner]: {
        amount: 3,
        units: 'years',
    },
};

export const UploadAdvisoryDocumentModal = ({
    show,
    onClose,
    contactGroupId,
    inAdvisoryFlow = false,
}: UploadAdvisoryDocumentModalProps) => {
    const { t } = useTranslation();
    const {
        onContinue,
        saveInProgress,
        downloadDocument,
        downloadDocumentInProgress,
    } = useAdvisoryDocumentBase();
    const [uploadedDoc, setUploadedDoc] = useState<DocumentType | null>(null);

    const schema = useMemo(() => object()
        .shape({
            date: string()
                .required(t('advisoryDocuments.uploadDocument.requiredField.advisoryDate'))
                .nullable(),
            number: string()
                .matches(NUMBER_AND_LETTERS_REGEX, t('advisoryDocuments.uploadDocument.numbersAndLettersOnly'))
                .required(t('advisoryDocuments.uploadDocument.requiredField.advisoryNumber')),
            system: string()
                .required(t('advisoryDocuments.uploadDocument.requiredField.advisorySystem')),
            investmentAmount: string()
                .required(t('advisoryDocuments.uploadDocument.requiredField.investmentAmount')),
            file: mixed()
                .required(t('advisoryDocuments.uploadDocument.requiredField.advisoryDocument'))
                .nullable(),
        }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        reset,
        setError,
    } = useForm<AdvisoryDocumentForm>({
        resolver,
        defaultValues: {
            date: null,
            number: '',
            system: '',
            file: null,
        },
        shouldFocusError: false,
    });

    const date = watch('date');
    const selectedSystem = watch('system');

    const advisorySystemOptions = useMemo(() => [
        ...(selectedSystem === '' ? [{
            value: '',
            label: <div className="empty-item"/>,
        }] : []),
        ...Object.keys(AdvisorySystem)
            .map(key => ({
                value: AdvisorySystem[key],
                label: key,
            })),
    ], [t, selectedSystem]);

    const advisoryExpiryDate = useMemo(() => {
        const selectedSystemNumber = parseInt(selectedSystem, 10);

        return (
            (date && (typeof selectedSystem === 'number')) ? moment(date)
                .add(
                    ValidityPeriodsByAdvisorySystem[selectedSystemNumber].amount,
                    ValidityPeriodsByAdvisorySystem[selectedSystemNumber].units,
                ) : null);
    },
    [selectedSystem, date]);

    const hasAdvisoryExpired = useMemo(() => advisoryExpiryDate && advisoryExpiryDate < moment(), [advisoryExpiryDate]);

    const onCancel = useCallback((documentItem: DocumentType | null) => {
        if (onClose) {
            onClose(documentItem);
        }
        reset();
        setUploadedDoc(null);
    }, [reset, onClose]);

    const addDocument = handleSubmit(async values => {
        try {
            const fileBase64 = await getBase64FromFile(values.file || '');
            const splitFileString = fileBase64.split(',');

            const payload: DocumentPayload = {
                investmentAmount: +values.investmentAmount,
                contactGroupId,
                date: new Date(values.date!),
                number: values.number,
                system: parseInt(values.system, 10),
                document: {
                    filename: values.file?.name || '',
                    file: splitFileString?.[1],
                },
            };

            const docItem = await onContinue(payload);

            if (inAdvisoryFlow) {
                setUploadedDoc(docItem);
            } else {
                onCancel(docItem);
            }
        } catch (err) {
            console.log(err);
            notification.open({
                content: t('general.somethingWentWrong'),
                type: 'error',
            });
        }
    });

    return (
        <Modal
            className="advisory-document-modal"
            visible={show}
            onCancel={() => onCancel(null)}
            title={t('advisoryDocuments.uploadDocument.title')}
            onOk={uploadedDoc ? () => onCancel(uploadedDoc) : addDocument}
            okText={t('advisoryDocuments.uploadDocument.continueToProductSelection')}
            cancelText={t('clientDashboard.cancel')}
            confirmLoading={saveInProgress}
            width={780}
            okButtonProps={{ disabled: saveInProgress || hasAdvisoryExpired }}
            cancelButtonProps={{ disabled: saveInProgress }}
        >
            <div className="modal-body">
                <p>{t('advisoryDocuments.uploadDocument.advisoryDate')}</p>

                <Controller
                    name="date"
                    control={control}
                    render={({
                        field: {
                            onChange,
                            value,
                        },
                    }) => (
                        <>
                            {/* @ts-ignore */}
                            <DatePicker
                                disabled={!!uploadedDoc}
                                format={dateFormatZurich}
                                value={value && formatDateWithoutLocalConversion(value, dateFormatZurich)}
                                error={errors?.date?.message}
                                disabledDate={(current) => current && (!compareDates(new Date(), current))}
                                onChange={(momentDate) => onChange(momentDate)}
                                style={{ border: errors?.date ? '2px solid #fe7468' : '2px solid #C9D0D2' }}
                                required
                            />
                        </>
                    )}
                />
                <p>{t('advisoryDocuments.uploadDocument.advisoryNumber')}</p>
                <Controller
                    name="number"
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            disabled={!!uploadedDoc}
                            error={errors?.number?.message}
                            {...field}
                        />
                    )}
                />
                <p>{t('advisoryDocuments.uploadDocument.advisorySystem')}</p>
                <Controller
                    name="system"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            disabled={!!uploadedDoc}
                            error={errors?.system?.message}
                            options={advisorySystemOptions}
                        />
                    )}
                />
                <p>{t('advisoryDocuments.uploadDocument.investmentAmount')}</p>
                <Controller
                    name="investmentAmount"
                    control={control}
                    render={({ field }) => (
                        <NumberInput
                            {...field}
                            data-cy="investmentAmount"
                            disabled={!!uploadedDoc}
                            name="investmentAmount"
                            error={errors?.investmentAmount?.message}
                            thousandSeparator="'"
                            decimalSeparator="."
                            prefix="CHF "
                        />
                    )}
                />
                <Controller
                    name="file"
                    control={control}
                    render={({
                        field: {
                            onChange,
                            ref,
                        },
                    }) => (uploadedDoc ? (
                        <div className="uploaded-file-container">
                            <Button
                                type="link"
                                disabled={downloadDocumentInProgress}
                                onClick={() => downloadDocument(uploadedDoc.documentId)}
                            >
                                {uploadedDoc.fileName}
                                {downloadDocumentInProgress && <Loader/>}
                            </Button>
                            <Button type="link" onClick={() => setUploadedDoc(null)}>
                                {/* @ts-ignore */}
                                <Icon type={ICON_TYPE_CLOSE_SLIM} size={24}/>
                            </Button>
                        </div>
                    ) : (
                        <div className="upload-file-container">
                            <p>{t('advisoryDocuments.uploadDocument.fileLabel')}</p>
                            {/* @ts-ignore */}
                            <UploadDocuments
                                fileTypesAllowed={FILE_TYPES.pdf}
                                ref={ref}
                                onSuccess={(val) => {
                                    try {
                                        setError('file', { message: undefined });
                                        onChange(val[0]);
                                    } catch (err) {
                                        //
                                    }
                                }}
                                onError={(err) => {
                                    setError('file', { message: err });
                                }}
                                className={`uploadFile ${errors.file?.message ? 'error' : ''}`}
                                numberOfFiles={1}
                                maxMbFileSize={20}
                            />
                            {errors.file?.message && <Error>{(errors.file?.message).toString()}</Error>}
                        </div>
                    ))}
                />
                <div className="advisory-expiry">
                    <div>
                        <p>{t('advisoryDocuments.uploadDocument.validTo')}</p>
                        <span className={hasAdvisoryExpired ? 'expired' : ''}>
                            {advisoryExpiryDate?.format('DD.MM.YYYY')}
                        </span>
                    </div>
                    {hasAdvisoryExpired
                        && <div className="expiry-note">{t('advisoryDocuments.uploadDocument.expiryNote')}</div>}
                </div>
            </div>
        </Modal>
    );
};
