import { ApplicationStatus } from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { ApplicationDocumentType } from '../AdvisorySection/AdvisorySection.types';

export interface ProductApplicationsProps {
    applications: SingleApplicationData[];
    isAdvisoryExpired?: boolean;
}

export enum ApplicationTypesEnum {
    NEW = 'ApplicationType.NEW',
    CHANGE = 'ApplicationType.CHANGE',
    SELL = 'ApplicationType.SELL',
    BUY = 'ApplicationType.BUY',
}

export interface SingleApplicationData {
    id: number;
    status: ApplicationStatus;
    typeKey: ApplicationTypesEnum;
    type: string;
    productName?: string;
    applicationId?: number;
    createdDate?: string;
    validTill?: string;
    advisor?: string;
    createdBy?: string;
    investmentAmount?: number;
    depotNumber?: string;
    currentStrategy?: string;
    investedAmount?: number;
    amountToBeSold?: number;
    purchaseAmount?: number;
    submitted?: string;
    submittedBy?: string | undefined;
    partialSaleOrLiquidate?: boolean;
    withTransferDoc?: boolean;
    documents?: ApplicationDocumentType[];
    rubrik?: string;
    canBeDeleted: boolean;
    isSentCase: boolean;
}

export interface SingleApplicationRowProps {
    advisoryDocNotCompleted?: boolean;
    isAdvisoryExpired?: boolean;
    data: SingleApplicationData;
}
