import {
    useCallback,
    useEffect, useMemo, useState,
} from 'react';
import isNil from 'lodash/isNil';
import {useTranslation} from 'react-i18next';
import {PRODUCTS, STRATEGIES, strategyTypes} from 'constants/constants';
import {findStrategyErrorMessage} from 'pages/ClientOverview/utils/utils';
import {NO_EXCLUSION_PREFERENCE} from 'pages/ClientOverview/pages/Portfolios/constants';
import {useModelList} from './useModelList';
import {useProductAllocation} from './useProductAllocation';
import {recommendedCategoryMap} from '../../../constants';

export const useStrategySelection = ({
    productId,
    recommendedStrategyScore,
    cachedModelPortfolioId,
    setPageErrors,
    cachedInvestmentHorizon,
    additionalDisableCheck = () => (false),
    additionalCheckForProductList = () => (true),
    productSettings,
    cachedSetupWithdrawalPlan,
    is3bProduct,
    cachedPreferenceStock,
    cachedPreferenceExclusion,
    cachedExclusionSubstitute,
    cachedPreferenceInclusion,
    isZiv,
    isZic,
    isAzp,
    isZifd,
}) => {
    const {t} = useTranslation();
    const [individualPortfolioData, setIndividualPortfolioData] = useState([]);
    const [selectedStrategyId, setSelectedStrategyId] = useState(0);
    const [selectedProductMarkId, setSelectedProductMarkId] = useState(0);
    const [productMarksOptions, setProductMarksOptions] = useState([]);
    const [showChart, setShowChart] = useState(false);
    const [setupWithdrawalPlan, setSetupWithdrawalPlan] = useState(false);
    const [preferenceStock, onPreferenceStockChange] = useState();
    const [preferenceExclusion, onPreferenceExclusionChange] = useState();
    const [preferenceInclusion, onPreferenceInclusionChange] = useState();
    const [exclusionSubstitute, onExclusionSubstituteChange] = useState();

    const [strategySelectedError, setStrategySelectedError] = useState(false);

    const [investmentHorizon, setInvestmentHorizon] = useState('');
    const [investmentHorizonError, setInvestmentHorizonError] = useState(null);
    const [isRebalance, setRebalance] = useState(true);
    const [individualPortfolioValidationError, setIndividualPortfolioValidationError] = useState();
    const {
        strategies, products, isLoading: isModalListLoading, error: modalListError,
    } = useModelList({productId});

    const strategy = useMemo(
        () => strategies.find(item => item.Id === selectedStrategyId),
        [strategies, selectedStrategyId],
    );
    const isIndividualPortfolioSelected = useMemo(() => strategy?.Type === strategyTypes.INDIVIDUAL, [strategy]);

    const {
        allocations,
        isLoading: isAllocationsLoading,
        factsheetUrl,
        modelPortfolioDetails,
        error: allocationError,
        riskScore,
    } = useProductAllocation({productId: selectedProductMarkId, individualPortfolioData: isIndividualPortfolioSelected ? individualPortfolioData : {}});

    const recommendedStrategy = useMemo(() => {
        if (investmentHorizon?.length) {
            const score = parseInt(recommendedStrategyScore, 10);

            return recommendedCategoryMap[parseInt(investmentHorizon, 10)](score);
        }

        return undefined;
    }, [recommendedStrategyScore, investmentHorizon]);

    const isZifdExclInterestIncome = useMemo(() => isZifd
     && selectedStrategyId
      && selectedStrategyId !== STRATEGIES.interest
       && selectedStrategyId !== STRATEGIES.income, [isZifd, selectedStrategyId]);

    const onSetupWithdrawalPlanChange = (value) => {
        setSetupWithdrawalPlan(value);
    };

    const checkTheRisk = useCallback((callback) => {
        if (!isIndividualPortfolioSelected) return null;

        if (riskScore > recommendedStrategy?.Risk) {
            if (productSettings?.productKey?.toLowerCase() === PRODUCTS.fzp) {
                return {
                    title: t('portfolio.disclaimer'),
                    content: t('portfolio.createNewPortfolio.strategy.fzpHigherRiskNotAllowed'),
                    cancelText: false,
                };
            }

            return {
                title: t('portfolio.disclaimer'),
                content: t('portfolio.createNewPortfolio.strategy.disclaimerText.high'),
                okText: t('clientDashboard.confirm'),
                onOk: callback,
                cancelText: false,
            };
        }

        if (riskScore < recommendedStrategy?.Risk) {
            return {
                title: t('portfolio.disclaimer'),
                content: t('portfolio.createNewPortfolio.strategy.disclaimerText.low'),
                okText: t('clientDashboard.confirm'),
                onOk: callback,
                cancelText: false,
            };
        }

        return null;
    }, [isIndividualPortfolioSelected, riskScore, recommendedStrategy?.Risk, t]);

    useEffect(() => {
        const value = cachedInvestmentHorizon;

        setInvestmentHorizon(String(!isNil(value) ? value : ''));
    }, [cachedInvestmentHorizon]);

    useEffect(() => { setSetupWithdrawalPlan(cachedSetupWithdrawalPlan || isAzp || false); }, [cachedSetupWithdrawalPlan, isAzp]);

    useEffect(() => { onExclusionSubstituteChange(cachedExclusionSubstitute); }, [cachedExclusionSubstitute]);
    useEffect(() => { onPreferenceExclusionChange(cachedPreferenceExclusion); }, [cachedPreferenceExclusion]);
    useEffect(() => { onPreferenceStockChange(cachedPreferenceStock); }, [cachedPreferenceStock]);
    useEffect(() => { onPreferenceInclusionChange(cachedPreferenceInclusion); }, [cachedPreferenceInclusion]);

    const selectedProduct = useMemo(
        () => {
            const product = products.find(item => item.Id === selectedProductMarkId) || {};

            product.CalculatedRiskScore = riskScore;

            return product;
        },
        [products, selectedProductMarkId, riskScore],
    );

    useEffect(() => {
        const isProductSelected = selectedProduct?.Id;

        if (!isProductSelected) {
            setStrategySelectedError({message: 'portfolio.createNewPortfolio.selectStrategy.selectedProductError', isError: true});

            return;
        }

        findStrategyErrorMessage(({
            is3bProduct, isZic, isAzp, selectedStrategyId, setStrategySelectedError,
        }));
    }, [selectedStrategyId, strategies, isZic, isAzp, is3bProduct, selectedProduct]);

    const onProductMarkClick = (event) => {
        setSelectedProductMarkId(+event.target.value);
    };

    const onInvestmentHorizonChange = (data) => {
        setInvestmentHorizon(String(data.value));
        setInvestmentHorizonError(null);
    };

    const handleChartShown = useCallback(() => {
        if (isIndividualPortfolioSelected && !individualPortfolioData?.footer?.allocation) {
            setShowChart(false);
        } else {
            setShowChart(true);
        }
    }, [isIndividualPortfolioSelected, individualPortfolioData?.footer?.allocation]);

    useEffect(() => {
        if (selectedProductMarkId) {
            (async () => handleChartShown())();
        } else {
            setShowChart(false);
        }
    }, [selectedProductMarkId, handleChartShown]);

    useEffect(() => {
        setPageErrors(
            modalListError || allocationError || individualPortfolioValidationError,
        );
    }, [modalListError, allocationError, individualPortfolioValidationError]);

    useEffect(() => {
        if (!strategy) {
            return;
        }

        let tempProducts = products;

        if (is3bProduct) {
            tempProducts = setupWithdrawalPlan && !isZifdExclInterestIncome ? tempProducts.filter(product => JSON.parse(product?.Attributes || '{}')?.WithdrawalOption) : tempProducts.filter(product => !JSON.parse(product?.Attributes || '{}')?.WithdrawalOption);
            if (isZiv) {
                // Filtering of the ZIVV products based on users preference strategy
                const preferenceFilters = {
                    preferenceStocks: preferenceStock && parseInt(preferenceStock, 10),
                    ...(selectedStrategyId === STRATEGIES.capital ? {preferenceInclusion: preferenceInclusion && parseInt(preferenceInclusion, 10)}
                        : {preferenceExclusion: preferenceExclusion && parseInt(preferenceExclusion, 10), substituteExclusion: exclusionSubstitute && parseInt(exclusionSubstitute, 10)}),
                };

                Object.keys(preferenceFilters).forEach(key => ((preferenceFilters[key] === undefined && key !== 'substituteExclusion') || (preferenceFilters[key] === undefined && parseInt(preferenceExclusion, 10) === NO_EXCLUSION_PREFERENCE)) && delete preferenceFilters[key]);

                tempProducts = tempProducts.filter(product => {
                    const productAttributes = JSON.parse(product?.Attributes || '{}');

                    const keys = Object.keys(preferenceFilters);
                    const isMatching = keys?.every(key => productAttributes[key] === preferenceFilters[key]);

                    if (!isMatching) {
                        return false;
                    }

                    return true;
                });
            }
        }

        const duplicateProductMarks = tempProducts.filter((product) => products.filter(item => item.Name === product.Name).length > 1);

        setProductMarksOptions(tempProducts.sort((a, b) => a.RiskScore - b.RiskScore).reduce((acc, product) => {
            const duplicateProductName = duplicateProductMarks.find(item => item.Id === product.Id);

            if (
                (duplicateProductName
                && duplicateProductMarks.some(item => item.RiskCategory.Id === strategy.Id)
                && strategy.Id !== duplicateProductName.RiskCategory.Id)
                || product?.Type === strategyTypes.INDIVIDUAL
            ) {
                return acc;
            }

            return [
                ...acc,
                {
                    label: product.Name,
                    value: product.Id.toString(),
                    disabled: !strategy.Products.includes(product.Id) || additionalDisableCheck(product),
                },
            ];
        }, []).filter((item, index, thisArr) => thisArr.findIndex(i => i.label === item.label) === index));
        const strategyProducts = tempProducts.filter((product) => strategy.Products.includes(product.Id));

        const product = strategyProducts.reduce((selected, item) => {
            if (selected.RiskScore > item.RiskScore && additionalCheckForProductList(item)) {
                return item;
            }

            return selected;
        }, {RiskScore: Infinity, Id: ''});

        if (!selectedProductMarkId && cachedModelPortfolioId && !isZiv) {
            setSelectedProductMarkId(cachedModelPortfolioId);
        } else {
            setSelectedProductMarkId(product.Id);
        }

        handleChartShown();
    }, [
        selectedStrategyId,
        products,
        strategies,
        strategy,
        cachedModelPortfolioId,
        setupWithdrawalPlan,
        is3bProduct,
        isZiv,
        isZifdExclInterestIncome,
        exclusionSubstitute,
        preferenceExclusion,
        preferenceStock,
        preferenceInclusion,
    ]);

    useEffect(() => {
        if (isZifdExclInterestIncome) {
            setSetupWithdrawalPlan(false);
        }
    }, [isZifdExclInterestIncome]);

    return {
        investmentHorizon,
        selectedStrategyId,
        recommendedStrategy,
        selectedProductMarkId,
        setIndividualPortfolioData,
        strategies,
        individualPortfolioData,
        onInvestmentHorizonChange,
        onProductMarkClick,
        onSetupWithdrawalPlanChange,
        setupWithdrawalPlan,
        preferenceStock,
        onPreferenceStockChange,
        preferenceExclusion,
        onPreferenceExclusionChange,
        preferenceInclusion,
        onPreferenceInclusionChange,
        exclusionSubstitute,
        onExclusionSubstituteChange,
        setSelectedStrategyId,
        strategy,
        isIndividualPortfolioSelected,
        productMarksOptions,
        selectedProduct,
        products,
        isNavigationDisabled: isModalListLoading || isAllocationsLoading || !!individualPortfolioValidationError || strategySelectedError.isError,
        isModalListLoading,
        isAllocationsLoading,
        showChart,
        modelPortfolioDetails,
        setIndividualPortfolioValidationError,
        investmentHorizonError,
        setInvestmentHorizonError,
        allocations,
        factsheetUrl,
        isRebalance,
        setRebalance,
        riskScore,
        checkTheRisk,
        productKey: productSettings?.productKey,
        strategySelectedError,
        isZifdExclInterestIncome,
    };
};
