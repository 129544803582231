/* eslint-disable eqeqeq */
import {useCallback, useEffect, useMemo, useState,} from 'react';
import {v4 as uuidv4} from 'uuid';
import {APPLICATION_CONTAINER_ID, OPTION_LIST, STRATEGIES, strategyTypes, THREEBTYPES,} from 'constants/constants';

import {useOptionList} from 'hooks/useOptionList';
import {useModelPortfolioList} from 'hooks/rest/useModelPortfolioList';
import ServiceManager from 'services/ServiceManager';
import {notification} from 'ui-library';

import {useGenericAPIMutation} from 'hooks/mutations/useGenericAPIMutation';
import {NO_EXCLUSION_PREFERENCE} from 'pages/ClientOverview/pages/Portfolios/constants';
import {useCreatePortfolioActions} from 'datasource/CreatePortfolio';
import {ModalStatusProps} from './ShoppingCartProductRow.types';

const useExistingProducts = ({
    portfolio,
    language,
    t,
    refetchExistingPlans,
    advisoryDocumentId,
}): any => {
    const {
        products: modelPortfolios,
        strategies,
        isLoading: modelPortfoliosLoading,
        refetch: refetchModelPortfolios,
    } = useModelPortfolioList({
        productId: portfolio?.existingProduct?.id,
        enabled: false,
    });

    const {
        saveInvestmentApplicationDataToStoreAction,
        removeInvestmentApplicationDataAction
    } = useCreatePortfolioActions();

    const changedInvestmentStrategy = useMemo(() => portfolio?.changedStrategy?.investmentDescription?.selectStrategy?.modelPortfolioId, [portfolio?.changedStrategy?.investmentDescription?.selectStrategy?.modelPortfolioId]);

    const selectedModelPortfolio = useMemo(
        () => modelPortfolios?.find(item => item?.Id === (changedInvestmentStrategy || portfolio?.modelPortfolioId)),
        [modelPortfolios, portfolio?.modelPortfolioId, changedInvestmentStrategy],
    );

    const selectedStrategyId = useMemo(() => selectedModelPortfolio?.RiskCategory?.Id, [selectedModelPortfolio?.RiskCategory?.Id]);

    const [strategy, setStrategy] = useState(
        changedInvestmentStrategy
        || portfolio?.modelPortfolioId,
    );
    const [investment, setInvestment] = useState({
        isSell: portfolio?.changedAmount ? portfolio?.changedAmount < 0 : false,
        amount: Math.abs(portfolio?.changedAmount) || '0.00',
    });

    const [selectedStrategyIdState, setSelectedStrategyIdState] = useState<
        number | null
    >(selectedStrategyId);
    const [preferenceStockState, setPreferenceStockState] = useState<
        string | undefined
    >(undefined);
    const [preferenceExclusionState, setPreferenceExclusionState] = useState<
        string | undefined
    >(undefined);
    const [exclusionSubstituteState, setExclusionSubstituteState] = useState<
        string | undefined
    >(undefined);
    const [preferenceInclusionState, setPreferenceInclusionState] = useState<
        string | undefined
    >(undefined);

    const [selectedProductMarkId, setSelectedProductMarkId] = useState(0);

    const isCapital = selectedStrategyIdState === STRATEGIES.capital;

    const {
        lists: [
            optionPreferenceStock = [],
            optionPreferenceExclusion = [],
            optionPreferenceInclusion = [],
        ],
    } = useOptionList([
        OPTION_LIST.preferenceStocks,
        OPTION_LIST.preferenceExclusion,
        OPTION_LIST.preferenceInclusion,
    ]);

    const [optionExclusionSubstitute, setOptionExclusionSubstitute] = useState<
        any[]
    >([]);

    const NO_EXCLUSION = optionPreferenceExclusion
        ? optionPreferenceExclusion[0]?.value
        : 0;

    useEffect(() => {
        if (isCapital) {
            if (
                (!preferenceStockState || !preferenceInclusionState)
                && optionPreferenceExclusion?.length
                && optionPreferenceStock?.length
                && optionPreferenceInclusion
                && optionPreferenceExclusion?.length > 0
                && optionPreferenceStock?.length > 0
            ) {
                if (!preferenceStockState) {
                    setPreferenceStockState(
                        optionPreferenceStock[0]?.value as string,
                    );
                }
                setPreferenceInclusionState(
                    optionPreferenceInclusion[0]?.value as string,
                );
            }
        } else if (
            (!preferenceStockState || !preferenceExclusionState)
            && optionPreferenceStock
            && optionPreferenceExclusion
            && optionPreferenceExclusion?.length > 0
            && optionPreferenceStock?.length > 0
        ) {
            setPreferenceStockState(optionPreferenceStock[0]?.value as string);
            setPreferenceExclusionState(
                optionPreferenceExclusion[0]?.value as string,
            );
            setExclusionSubstituteState(undefined);
        }
    }, [
        selectedStrategyId,
        optionPreferenceStock,
        optionPreferenceExclusion,
        optionPreferenceInclusion,
        isCapital,
        preferenceStockState,
        preferenceExclusionState,
        preferenceInclusionState,
    ]);

    useEffect(() => {
        if (selectedStrategyId) {
            setSelectedStrategyIdState(selectedStrategyId);
        }

        setStrategy(changedInvestmentStrategy || portfolio?.modelPortfolioId);
    }, [selectedStrategyId, changedInvestmentStrategy, portfolio?.modelPortfolioId]);

    const getSubstituteExclusionOptions = async () => {
        try {
            const {data} = await ServiceManager.customInvestmentService(
                'getSubstituteExclusionOptions',
                [
                    {
                        filter: optionPreferenceExclusion?.find(
                            (i) => i?.value === preferenceExclusionState,
                        )?.label,
                        preferenceKey: 'substituteExclusion',
                        language,
                    },
                ],
            );

            if (data) {
                const tempSubstituteExclusionOptions = data?.items?.map(
                    (item) => ({
                        ...item,
                        value: `${item.id}`
                    }),
                );

                setOptionExclusionSubstitute(tempSubstituteExclusionOptions);
            }
            setExclusionSubstituteState(undefined);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (
            preferenceExclusionState
            && preferenceExclusionState !== NO_EXCLUSION
        ) {
            getSubstituteExclusionOptions();
        }
    }, [preferenceExclusionState, language]);

    const {
        mutateAsync: handleChangeExisting3bPlan,
        isPending: changeExisting3bPlanResponseIsPending
    } = useGenericAPIMutation();

    const [modalStatus, setModalStatus] = useState<ModalStatusProps>({
        open: false,
        product: undefined,
        field: undefined,
    });

    const isZIC = useMemo(
        () => portfolio?.existingProduct?.externalId === THREEBTYPES.zic,
        [portfolio?.existingProduct?.externalId],
    );

    const showEditIcon = useMemo(
        () => portfolio?.editable && !portfolio?.pendingCEPRequest,
        [portfolio?.editable, portfolio?.pendingCEPRequest],
    );

    useEffect(() => {
        if (selectedStrategyId === STRATEGIES.individual) {
            setStrategy(undefined);
        } else {
            setStrategy(
                changedInvestmentStrategy
                || portfolio?.modelPortfolioId,
            );
        }
        setInvestment({
            isSell: isZIC || (portfolio?.changedAmount
                ? portfolio?.changedAmount < 0
                : false),
            amount: Math.abs(portfolio?.changedAmount) || '0.00',
        });
    }, [portfolio?.modelPortfolioId, portfolio?.changedAmount, selectedStrategyId]);

    const selectedStrategy = useMemo(
        () => strategies?.find(item => item?.Id === selectedStrategyIdState),
        [strategies, selectedStrategyIdState],
    );

    const getIsStrategyDisabled = useCallback(
        (strtgy) => {
            if (
                !selectedStrategy?.Products?.includes(strtgy?.Id)
                || strtgy?.Id
                === (changedInvestmentStrategy
                    || portfolio?.modelPortfolioId)
                || strtgy?.WithdrawalOption !== !!portfolio?.withdrawalAmount
            ) {
                return true;
            }

            return false;
        },
        [portfolio?.modelPortfolioId, changedInvestmentStrategy, portfolio?.withdrawalAmount, selectedStrategy?.Products],
    );

    const strategyOptions = useMemo(
        () => modelPortfolios?.filter(p => p?.Type !== strategyTypes.INDIVIDUAL)
            ?.map(
                (port) => ({
                    value: port?.Id,
                    label: port?.Name,
                    disabled: getIsStrategyDisabled(port),
                }),
            ),
        [modelPortfolios, getIsStrategyDisabled],
    );

    const baseStrategyOptions = useMemo(
        () => strategies?.map(
            (strtg) => ({
                value: strtg?.Id,
                label: strtg?.Name,
                disabled: strtg?.Id === selectedStrategyId || strtg?.Id === STRATEGIES.individual || (!!portfolio?.withdrawalAmount && strtg?.Id > 2),
            }),
        ),
        [strategies, selectedStrategyId, portfolio?.withdrawalAmount],
    );

    const selectedProduct = useMemo(
        () => {
            const product = modelPortfolios?.find(item => item.Id === selectedProductMarkId) || {};

            return product;
        },
        [modelPortfolios, selectedProductMarkId],
    );

    const handleEditStrategy = useCallback((data: ModalStatusProps) => {
        setModalStatus(data);
        refetchModelPortfolios();
    }, []);

    const onChangeStrategy = useCallback(async () => {
        const containerId = global.sessionStorage.getItem(APPLICATION_CONTAINER_ID);

        const uuid = uuidv4();

        if (!containerId) {
            global.sessionStorage.setItem(APPLICATION_CONTAINER_ID, uuid);
        }

        const payloadModelPortfolioId = portfolio?.existingProduct?.externalId === THREEBTYPES?.zivv ? selectedProduct?.Id : strategy;

        if (!!portfolio?.id && payloadModelPortfolioId) {
            let result: any = {};

            if (portfolio?.changedStrategy?.investmentApplicationId) {
                result = await handleChangeExisting3bPlan({
                    service: 'customInvestmentService',
                    API: 'putChangeExisting3bStrategy',
                    payload: {
                        applicationId:
                        portfolio?.changedStrategy?.investmentApplicationId,
                        modelPortfolioId: payloadModelPortfolioId as number,
                        strategyId: selectedStrategyIdState as number,
                    },
                });
            } else {
                result = await handleChangeExisting3bPlan({
                    service: 'customInvestmentService',
                    API: 'postChangeExisting3bStrategy',
                    payload: {
                        portfolioId: portfolio?.id as number,
                        modelPortfolioId: payloadModelPortfolioId as number,
                        containerId: (containerId || uuid) as string,
                        advisoryDocumentId,
                        strategyId: selectedStrategyIdState as number,
                    },
                });
            }

            if (result?.status === 200) {
                saveInvestmentApplicationDataToStoreAction({payload: result?.data});
            }
            refetchExistingPlans();
            setModalStatus({
                open: false,
                product: undefined,
                field: undefined,
            });
        }
    }, [
        strategy,
        selectedProduct,
        portfolio?.id,
        portfolio?.changedStrategy?.investmentApplicationId,
    ]);

    const changeInvestment = useCallback((amount: number) => {
        setInvestment(prev => ({
            ...prev,
            amount,
        }));
    }, []);

    const changeBuySell = useCallback((isSell: boolean) => {
        setInvestment(prev => ({
            ...prev,
            isSell,
        }));
    }, []);

    const onChangeInvestmentPlan = useCallback(async () => {
        const containerId = global.sessionStorage.getItem(APPLICATION_CONTAINER_ID);

        const uuid = uuidv4();

        if (!containerId) {
            global.sessionStorage.setItem(APPLICATION_CONTAINER_ID, uuid);
        }

        if (portfolio?.id) {
            let result: any = {};

            if (portfolio?.changedBuySellAmount?.investmentApplicationId) {
                result = await handleChangeExisting3bPlan({
                    service: 'customInvestmentService',
                    API: 'putChangeExisting3bInvestmentAmount',
                    payload: {
                        applicationId:
                        portfolio?.changedBuySellAmount?.investmentApplicationId,
                        isSell: investment?.isSell,
                        amount: Math.abs(+investment?.amount),
                    },
                });
            } else {
                result = await handleChangeExisting3bPlan({
                    service: 'customInvestmentService',
                    API: 'postChangeExisting3bInvestmentAmount',
                    payload: {
                        portfolioId: portfolio?.id as number,
                        advisoryDocumentId,
                        containerId: (containerId || uuid) as string,
                        isSell: investment?.isSell,
                        amount: Math.abs(+investment?.amount),
                    },
                });
            }
            if (result?.status === 200) {
                saveInvestmentApplicationDataToStoreAction({payload: result?.data});
            }
            refetchExistingPlans();
            setModalStatus({
                open: false,
                product: undefined,
                field: undefined,
            });
        }
    }, [investment, portfolio?.id, portfolio?.changedBuySellAmount?.investmentApplicationId, advisoryDocumentId]);

    useEffect(() => {
        if (!selectedStrategy || modalStatus?.product !== THREEBTYPES.zivv) {
            return;
        }

        let tempProducts = modelPortfolios;

        tempProducts = selectedModelPortfolio?.WithdrawalOption ? tempProducts.filter(product => JSON.parse(product?.Attributes || '{}')?.WithdrawalOption) : tempProducts.filter(product => !JSON.parse(product?.Attributes || '{}')?.WithdrawalOption);

        const preferenceFilters = {
            preferenceStocks: preferenceStockState && parseInt(preferenceStockState, 10),
            ...(selectedStrategyIdState === STRATEGIES.capital ? {preferenceInclusion: preferenceInclusionState && parseInt(preferenceInclusionState, 10)}
                : {
                    preferenceExclusion: preferenceExclusionState && parseInt(preferenceExclusionState, 10),
                    substituteExclusion: exclusionSubstituteState && parseInt(exclusionSubstituteState, 10)
                }),
        };

        Object.keys(preferenceFilters)
            .forEach(key => ((preferenceFilters[key] === undefined && key !== 'substituteExclusion') || (preferenceFilters[key] === undefined && parseInt(preferenceExclusionState as string, 10) === NO_EXCLUSION_PREFERENCE)) && delete preferenceFilters[key]);

        tempProducts = tempProducts?.filter(product => {
            const productAttributes = JSON.parse(product?.Attributes || '{}');

            const keys = Object.keys(preferenceFilters);
            const isMatching = keys?.every(key => productAttributes[key] === preferenceFilters[key]);

            if (!isMatching) {
                return false;
            }

            return true;
        });

        const strategyProducts = tempProducts?.filter((product) => selectedStrategy?.Products?.includes(product?.Id));

        const product = strategyProducts?.reduce((selected, item) => {
            if (selected.RiskScore > item.RiskScore) {
                return item;
            }

            return selected;
        }, {
            RiskScore: Infinity,
            Id: ''
        });

        setSelectedProductMarkId(product?.Id);
    }, [
        selectedStrategyIdState,
        modelPortfolios,
        strategies,
        selectedStrategy,
        exclusionSubstituteState,
        preferenceExclusionState,
        preferenceStockState,
        preferenceInclusionState,
    ]);

    const handleDiscard3bApplication = useCallback(() => {
        const func1 = portfolio?.changedStrategy?.investmentApplicationId
            ? handleChangeExisting3bPlan({
                service: 'customInvestmentService',
                API: 'deleteInvestmentApplication',
                payload: {investmentApplicationId: portfolio?.changedStrategy?.investmentApplicationId},
            })
            : null;

        const func2 = portfolio?.changedBuySellAmount?.investmentApplicationId
            ? handleChangeExisting3bPlan({
                service: 'customInvestmentService',
                API: 'deleteInvestmentApplication',
                payload: {investmentApplicationId: portfolio?.changedBuySellAmount?.investmentApplicationId},
            })
            : null;

        const promises = [func1, func2].filter(Boolean);

        Promise.allSettled(promises)
            .then((data) => {
                if (data?.[0]?.value?.status === 200) {
                    removeInvestmentApplicationDataAction({
                        id: portfolio?.changedStrategy?.investmentApplicationId || portfolio?.changedBuySellAmount?.investmentApplicationId,
                    });
                }
                if (data?.[1]?.value?.status === 200) {
                    removeInvestmentApplicationDataAction({
                        id: portfolio?.changedBuySellAmount?.investmentApplicationId,
                    });
                }
                notification.open({
                    content: t('dashboard.overviewClientApplications.canceledSuccessfully'),
                    type: 'success'
                });
            })
            .catch(() => {
                notification.open({
                    content: t('dashboard.overviewClientApplications.cancelError'),
                    type: 'error'
                });
            })
            .finally(() => {
                refetchExistingPlans();
            });
    }, [
        t,
        portfolio?.changedStrategy?.investmentApplicationId,
        portfolio?.changedBuySellAmount?.investmentApplicationId,
    ]);

    return {
        modalStatus,
        setModalStatus,
        showEditIcon,
        handleEditStrategy,
        strategy,
        setStrategy,
        onChangeStrategy,
        modelPortfoliosLoading,
        strategyOptions,
        onChangeInvestmentPlan,
        investment,
        changeInvestment,
        changeBuySell,
        NO_EXCLUSION,
        baseStrategyOptions,
        selectedStrategyIdState,
        setSelectedStrategyIdState,
        optionPreferenceStock,
        preferenceStockState,
        setPreferenceStockState,
        isCapital,
        preferenceInclusionState,
        optionPreferenceInclusion,
        setPreferenceInclusionState,
        optionPreferenceExclusion,
        preferenceExclusionState,
        setPreferenceExclusionState,
        optionExclusionSubstitute,
        exclusionSubstituteState,
        setExclusionSubstituteState,
        isZIC,
        selectedProduct,
        selectedModelPortfolio,
        handleDiscard3bApplication,
        changeExisting3bPlanResponseIsPending,
    };
};

export default useExistingProducts;
