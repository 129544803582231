import {
    Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { INVESTMENT_HORIZON_OPTIONS, recommendedCategoryMap } from 'pages/ClientOverview/constants';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { Modal } from 'ui-library';
import { useHistorialPerformanceData } from 'hooks/rest/useHistorialPerformanceData';
import { useRiskReturnChartData } from 'hooks/rest/useRiskReturnChartData';
import { useGetPortfolioVolatility } from 'hooks/rest/useGetPortfolioVolatility';
import { convertToMilliseconds } from 'utils';
import { PortfolioVolatilityResponse } from 'core/types/api/Api';
import { useCreatePortfolioActions, useCreatePortfolioSelectors } from '../CreatePortfolio';
import { getRiskScores } from './static';

type RiskScore = {
    id: number;
    label: string;
}

type RiskData = {
    x: number,
    y: number,
    z: number,
    name: number,
    color?: string
}

type HistoricalPerformanceData = {
    data: Array<number[]>;
    name: string;
    id: string | number;
}

type UseStrategy = {
    riskScores: Array<RiskScore>;
    investmentHorizon: {Id: string; Text: string}[];
    selectedHorizon: number | null;
    investmentHorizonError: boolean;
    validationErrorMessage: string;
    riskAnalysisData: RiskData[];
    historicalPerformanceData: HistoricalPerformanceData[];
    riskProfile: number | undefined;
    recommendedStrategy: {
        Id: number;
        Name: string;
        Min: number;
        Max: number;
        Risk: number;
    } | undefined;
    selectedRiskProfile: {
        id: number;
        label: string;
    } | undefined;
    chartModal: string | null;
    portfolioVolatilities: PortfolioVolatilityResponse[] | null | undefined,
    handleInvestmentHorizon: ({ value }: {value: string}) => void;
    onRiskScoreChange: (value: number) => void;
    setChartModal: Dispatch<SetStateAction<string | null>>;
    handleOpenChartInFullScreen: (param: string) => () => void;
    riskReturnChartLoading: boolean;
    historicalPerformanceChartLoading: boolean;
}

const useStrategy = (): UseStrategy => {
    const { t } = useTranslation();
    const riskScores = getRiskScores(t);
    const {
        currentRecommendedStrategySelector, advisoryIdSelector, advisoryDataSelector, isAzp,
    } = useCreatePortfolioSelectors();
    const { saveAdvisoryDataAction } = useCreatePortfolioActions();
    const { data: portfolioVolatilities } = useGetPortfolioVolatility({ isEnabled: !!advisoryIdSelector });

    const advisoryStrategy = advisoryDataSelector?.metadata?.advisoryStrategy || null;
    const recommendedStrategyScore = currentRecommendedStrategySelector?.Score;

    const { data: riskReturnChartData, statusCode: riskReturnChartStatus } = useRiskReturnChartData({ isEnabled: true });
    const { data: historicalPerformance, statusCode } = useHistorialPerformanceData({ isEnabled: true });

    const [recommendedStrategy, setRecommendedStrategy] = useState<any>(null);
    const [selectedHorizon, setSelectedHorizon] = useState<number | null>(advisoryStrategy?.investmentHorizon ?? null);
    const [investmentHorizonError, setInvestmentHorizonError] = useState<boolean>(false);
    const [continueButtonLoading, setContinueButtonLoading] = useState<boolean>(false);
    const [riskProfile, setRiskProfile] = useState(advisoryStrategy?.chosenRiskCategoryId);
    const [chartModal, setChartModal] = useState<string | null>(null);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const validationErrorMessage = useMemo(() => t('advisoryStrategySelection.validation.InvestmentHorizon'), [t]);

    const investmentHorizon = useMemo(() => INVESTMENT_HORIZON_OPTIONS(t, isAzp), [t, isAzp]);
    const selectedRiskProfile = useMemo(() => riskScores.find(f => f.id === riskProfile), [riskProfile, riskScores]);

    useEffect(() => {
        const selectedCategory = advisoryStrategy?.investmentHorizon && recommendedCategoryMap?.[advisoryStrategy?.investmentHorizon](+(recommendedStrategyScore as number));

        setRecommendedStrategy(selectedCategory);
    }, [advisoryStrategy?.investmentHorizon, recommendedStrategyScore]);

    const getColor = useCallback((id) => {
        if (recommendedStrategy?.Id === id) {
            return 'recommended';
        } if (riskProfile === id) {
            return 'selected';
        }

        return '';
    }, [recommendedStrategy, riskProfile]);

    const riskAnalysisData = useMemo(() => riskReturnChartData?.map((riskReturn) => {
        const data = {
            x: riskReturn.riskValue * 100,
            y: riskReturn.returnValue * 100,
            z: 2,
            name: riskReturn.riskCategoryId,
            color: getColor(riskReturn?.riskCategoryId),
        };

        return data;
    }), [riskReturnChartData, recommendedStrategy, riskProfile]) as RiskData[];

    const historicalPerformanceData = useMemo(() => historicalPerformance?.reduce((acc, performance) => {
        const data = performance?.values?.map(value => [convertToMilliseconds(value.date), value.performance]);

        const performanceData = riskScores?.find(score => score?.id === performance.investmentMetricType);

        acc.push({ id: performanceData?.id, name: performanceData?.label, data });

        return acc;
    }, []), [riskScores, historicalPerformance]);

    const onRiskScoreChange = useCallback((value: number) => {
        if (value !== recommendedStrategy?.Id && value !== riskProfile) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Modal.confirm({
                title: t('portfolio.disclaimer'),
                content: t(`portfolio.createNewPortfolio.strategy.disclaimerText.${value > (recommendedStrategy?.Id as number) ? 'high' : 'low'}`),
                okText: t('clientDashboard.confirm'),
                onOk: async () => {
                    setRiskProfile(value);
                    setIsDirty(true);
                },
                onCancel: () => setRiskProfile(prev => prev),
                cancelText: false,
                triggerCancelOnClose: true,
            });
        } else {
            setRiskProfile(value);
        }
    }, [recommendedStrategy?.Id, riskProfile]);

    const saveData = useCallback(async () => {
        const payload = {
            investmentHorizon: selectedHorizon,
            chosenRiskCategoryId: riskProfile,
            recommendedRiskCategoryId: recommendedStrategy?.Id,
        };

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'strategy',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                unlockNavigationAction();
                stepComplete();
            }
        }
    }, [selectedHorizon, riskProfile, recommendedStrategy?.Id, advisoryIdSelector]);

    const onContinue = useCallback(() => {
        if (selectedHorizon == null) {
            setInvestmentHorizonError(true);
        } else {
            setContinueButtonLoading(true);
            saveData();
        }
    }, [selectedHorizon, isDirty, riskProfile, validationErrorMessage, saveData]);

    const { stepComplete, unlockNavigationAction } = usePortfolioProcessNavigation({
        enableAutoStepComplete: false,
        saveOnContinue: onContinue,
        continueInProgress: continueButtonLoading,
    });

    const handleOpenChartInFullScreen = (param: string) => () => {
        setChartModal(param);
    };

    const handleInvestmentHorizon = useCallback(({ value }) => {
        setSelectedHorizon(+value);
        setInvestmentHorizonError(false);
        setIsDirty(true);
        const selectedCategory = recommendedCategoryMap?.[+value](+(recommendedStrategyScore as number));

        setRiskProfile(selectedCategory?.Id);
        setRecommendedStrategy(selectedCategory);
    }, [recommendedCategoryMap, t]);

    return {
        riskScores,
        riskAnalysisData,
        historicalPerformanceData,
        investmentHorizon,
        selectedHorizon,
        investmentHorizonError,
        validationErrorMessage,
        riskProfile,
        recommendedStrategy,
        selectedRiskProfile,
        chartModal,
        portfolioVolatilities: portfolioVolatilities?.portfolioVolatililties,
        handleInvestmentHorizon,
        onRiskScoreChange,
        setChartModal,
        handleOpenChartInFullScreen,
        historicalPerformanceChartLoading: statusCode === 'pending',
        riskReturnChartLoading: riskReturnChartStatus === 'pending',
    };
};

export default useStrategy;
