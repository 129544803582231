/* eslint-disable no-restricted-syntax */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    number, object, ObjectSchema, string,
} from 'yup';
import { useCreatePortfolioActions, useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';
import { AdvisoryOtherInvestmentsResponse, AllocationsResponse } from 'core/types/api/Api';
import { getIntegerObjectValues } from 'widgets/OtherAssets/utils';
import { getValidationMessage } from 'utils/validation';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { IsAllDataFalse } from 'validation/validation';
import { TPageError } from 'datasource/PortfolioProcessLayout/PortfolioFlowLayoutManager.types';
import { OtherAssetsFormData } from './useOtherAssets.types';

const STRATEGY_VALUE_FOR_INDIVIDUAL = 1507;

export const useOtherAssets = () => {
    const { t } = useTranslation();
    const [pageErrors, setPageErrors] = useState<TPageError|undefined>(undefined);
    const {
        advisoryIdSelector, advisoryDataSelector, advisoryOptions, strategyAllocations,
    } = useCreatePortfolioSelectors();
    const { saveAdvisoryDataAction } = useCreatePortfolioActions();
    const { category: categoryOptions, institute: instituteOptions, strategy: strategyOptions } = advisoryOptions || {};

    const otherInvestmentsData = useMemo(() => advisoryDataSelector?.metadata?.otherInvestment?.investments?.map(investment => {
        const {
            allocations, ...rest
        } = investment;

        const convertedAllocations = getIntegerObjectValues(allocations);

        return {
            allocations: convertedAllocations,
            ...rest,
        };
    }) || [], [advisoryDataSelector?.metadata?.otherInvestment?.investments, t]);

    const [assetsTableData, setAssetsTableData] = useState<OtherAssetsFormData[]>([]);
    const [defaultValues, setDefaultValues] = useState<any>(null);
    const [error, setError] = useState<string>('');
    const [showAddAssetModal, setShowAddAssetModal] = useState<boolean>(false);
    const [continueButtonLoading, setContinueButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        const modifiedAssestData = otherInvestmentsData?.map((data, index) => ({ ...data, id: `${Date.now()}_${index}` })) || [];

        setAssetsTableData(modifiedAssestData);
    }, [otherInvestmentsData]);

    const {
        mandatoryError, numberError,
    } = getValidationMessage();

    const numberInputValidation = number(mandatoryError)
        .typeError(numberError)
        .nullable(true, mandatoryError)
        .min(0)
        .max(100, 'Value must not be greater than 100%');

    const schema: ObjectSchema<AdvisoryOtherInvestmentsResponse> = useMemo(() => object().shape({
        categoryListItemId: number().nullable().required(mandatoryError),
        instituteListItemId: number().nullable().required(mandatoryError),
        description: string().nullable(),
        strategyListItemId: number().nullable(),
        value: number().nullable(false, mandatoryError).required(mandatoryError).typeError(mandatoryError),
        allocations: object().shape({
            stocks: numberInputValidation,
            liquidity: numberInputValidation,
            bonds: numberInputValidation,
            realEstate: numberInputValidation,
            rawMaterial: numberInputValidation,
            tiedAssets: numberInputValidation,
            other: numberInputValidation,
        }),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: { errors }, watch, reset, setValue,
    } = useForm({
        resolver,
        defaultValues: {},
    });

    const formData = watch();

    useEffect(() => {
        if (!defaultValues) {
            reset({});
        }
    }, [defaultValues]);

    const setStrategyValueIfNotSelected = useCallback(() => {
        const strategyIdForIndividual = strategyOptions?.data?.find(option => option.value === STRATEGY_VALUE_FOR_INDIVIDUAL)?.value;
        const {
            stocks, liquidity, bonds, realEstate, rawMaterial, tiedAssets, other,
        } = formData?.allocations || {};

        const isAllocationTouched = stocks || liquidity || bonds || realEstate || rawMaterial || tiedAssets || other;

        if (formData?.strategyListItemId == null && isAllocationTouched) {
            setValue('strategyListItemId', strategyIdForIndividual);
        }
        if (formData?.allocations && IsAllDataFalse(formData?.allocations) && formData?.strategyListItemId === strategyIdForIndividual) {
            setValue('strategyListItemId', null);
        }
    }, [formData, strategyOptions, setValue]);

    useEffect(() => {
        setStrategyValueIfNotSelected();
    }, [formData]);

    const onSubmit = useCallback(async () => {
        setContinueButtonLoading(true);
        const otherAssets: AdvisoryOtherInvestmentsResponse[] = assetsTableData?.map(asset => {
            const {
                allocations, value, id, ...rest
            } = asset || {};

            const modifiedAllocations = {};

            if (allocations) {
                Object.keys(allocations)?.forEach(key => {
                    modifiedAllocations[key] = (allocations[key] / 100 || 0);
                });
            }

            return {
                ...rest,
                value: +(value as number),
                allocations: modifiedAllocations,
            };
        }) || [];

        await saveData(otherAssets);
        setContinueButtonLoading(false);
    }, [assetsTableData]);

    const { stepComplete, unlockNavigationAction } = usePortfolioProcessNavigation({
        saveOnContinue: useCallback(onSubmit, [onSubmit]),
        pageErrors,
        continueInProgress: continueButtonLoading,
        enableAutoStepComplete: false,
    });

    const totalAssetPercentage = useMemo(() => {
        const {
            liquidity = 0,
            bonds = 0,
            other = 0,
            rawMaterial = 0,
            realEstate = 0,
            stocks = 0,
            tiedAssets = 0,
        } = formData?.allocations || {};

        return +(+liquidity + +bonds + +other + +rawMaterial + +realEstate + +stocks + +tiedAssets).toFixed(1);
    }, [formData]);

    const getTotalAssetsData = useCallback((array: any[]) => {
        let totalValue = 0;
        const totalAllocations: AllocationsResponse = {
            stocks: 0,
            liquidity: 0,
            bonds: 0,
            realEstate: 0,
            rawMaterial: 0,
            tiedAssets: 0,
            other: 0,
        };

        array.forEach(item => {
            totalValue += +item.value;
            for (const key in item.allocations) {
                if (Object.hasOwn(totalAllocations, key)) {
                    totalAllocations[key] += item.value * (item.allocations[key] / 100);
                }
            }
        });

        // Convert the total allocations to percentages based on the total value
        for (const key in totalAllocations) {
            if (Object.hasOwn(totalAllocations, key)) {
                totalAllocations[key] = ((totalAllocations[key] / +totalValue) * 100).toFixed(1);
            }
        }

        return {
            instituteListItemId: <strong>Total</strong>,
            value: +totalValue?.toFixed(1),
            allocations: totalAllocations,
        };
    }, []);

    const totalAssetsData = useMemo(() => getTotalAssetsData(assetsTableData), [assetsTableData, getTotalAssetsData]);

    const assetDataCategories = useMemo(() => ((items) => {
        const categories = new Set<number>();

        items.forEach(item => {
            if (item?.categoryListItemId) {
                categories.add(item?.categoryListItemId);
            }
        });

        return Array.from(categories);
    })(assetsTableData || []), [assetsTableData]);

    const isTableLoading = useMemo(() => categoryOptions?.status === 'pending' || instituteOptions?.status === 'pending' || strategyOptions?.status === 'pending', [categoryOptions?.status, instituteOptions?.status, strategyOptions?.status]);

    useEffect(() => {
        if (totalAssetPercentage === 100.0) {
            setError('');
        }
    }, [totalAssetPercentage]);

    const toggleAddAssetModal = useCallback((val: boolean) => {
        reset();
        setDefaultValues(null);
        setShowAddAssetModal(val);
        setError('');
    }, [reset, setDefaultValues, setShowAddAssetModal]);

    const handleAddAsset = useCallback((dta) => {
        if (totalAssetPercentage !== 100.0) {
            setError(t('OtherAssets.errors.totalAssetsPercentageError'));
        } else {
            if (defaultValues?.id) {
                setAssetsTableData((prevState: OtherAssetsFormData[]) => prevState?.map((prevData) => {
                    let nextData = { ...prevData };

                    if (nextData?.id === defaultValues?.id) {
                        nextData = { ...dta, id: defaultValues.id };
                    }

                    return nextData;
                }));
            } else {
                setAssetsTableData(prevState => [...prevState, { ...dta, id: Date.now() }]);
            }
            setShowAddAssetModal(false);
            reset();
            setDefaultValues(null);
        }
    }, [defaultValues, setAssetsTableData, totalAssetPercentage]);

    const handleEditAssetRow = useCallback((record: OtherAssetsFormData) => {
        setDefaultValues(record);
        reset(record);
        setShowAddAssetModal(true);
    }, [setDefaultValues, reset]);

    const deleteAsset = useCallback(() => {
        if (defaultValues?.id) {
            setAssetsTableData((prevState: OtherAssetsFormData[]) => prevState?.filter((prevData) => prevData?.id !== defaultValues?.id));
            setShowAddAssetModal(false);
            reset();
            setDefaultValues(null);
        }
    }, [defaultValues, setAssetsTableData]);

    const handleStrategyAllocations = useCallback((val: number) => {
        const specificAllocations = strategyAllocations?.find(allocation => allocation?.strategyListItemId === val);

        const updatedData = getIntegerObjectValues(specificAllocations);

        delete updatedData.strategyListItemId;

        setValue('allocations', { ...updatedData });
    }, [strategyAllocations, formData]);

    const saveData = async (data: AdvisoryOtherInvestmentsResponse[]) => {
        const payload = {
            otherInvestments: [...data],
        };

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'other-investments',
                payload,
            });

            if (result?.status === 200) {
                unlockNavigationAction();
                stepComplete();
            } else if (result?.error) {
                setPageErrors({
                    message: `${t('contactGroups.somethingWentWrong')} ${result?.error?.message}`,
                });
            }
        }
    };

    return {
        categoryOptions,
        instituteOptions,
        strategyOptions,
        defaultValues,
        error,
        showAddAssetModal,
        control,
        errors,
        assetsTableData,
        totalAssetsData,
        assetDataCategories,
        totalAssetPercentage,
        isTableLoading,
        handleAddAsset: handleSubmit(handleAddAsset),
        deleteAsset,
        handleStrategyAllocations,
        handleEditAssetRow,
        toggleAddAssetModal,
        handleSubmit,
    };
};
