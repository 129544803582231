import Preloader from 'components/Preloader';
import {Status} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/KYC/components/StatusBox';
import {
    StatusBox
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/KYC/components/StatusBox/StatusBox';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Icon, Title} from 'ui-library';
import './SelectedStrategy.css';
import {ICON_TYPE_INFOBOX} from 'ui-library/components/Icon';

const SelectedStrategy = ({
    selectedProductStrategy,
    factsheetUrl,
    errorMessage,
    disableOnError = false,
    isLoading = false,
    isInfoMessage = false,
}) => {
    const {t} = useTranslation();

    return (
        <Preloader isLoading={isLoading}>
            <div className="selectedStrategy">
                <Title type={3} className="selectedStrategy__title">{t('Your Investment Strategy')}</Title>
                {errorMessage && (
                    <StatusBox
                        status={isInfoMessage ? Status.Info : Status.Danger}
                        description={(
                            <div className="selectedStrategy__errorMessage">
                                {isInfoMessage && <Icon type={ICON_TYPE_INFOBOX} size={16}/>}
                                {errorMessage}
                            </div>
                        )}
                    />
                )}
                {!disableOnError && (

                    <div className="wrapper">
                        <b className="strategyTitle">{selectedProductStrategy}</b>
                        {!!factsheetUrl && (
                            <a
                                href={factsheetUrl}
                                target="_blank"
                                className="button"
                                rel="noreferrer"
                            >
                                {t('portfolio.createNewPortfolio.strategy.factsheetUrl')}
                            </a>
                        )}
                    </div>

                )}
            </div>
        </Preloader>
    );
};

export default SelectedStrategy;
